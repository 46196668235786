<template>
	<div class="">
		<el-dialog
			v-loading="listLoading"
			:visible.sync="isActiveDialog"
			:width="width+'px'"
			:style="cssVars"
			inline
			:before-close="handleCancel">
			<span slot="title" style="font-size: 14px;color: #606266;font-weight: 700;">
				<div class="required"></div><span>创建客户档案</span></span>
			
			<el-form :model="ruleForm" :rules="rules" size="mini" :inline="true" label-position="top" ref="ruleForm">
				
				
				<!--一行2个选项两端对齐-->
				<div style="display: flex; justify-content: space-between">
					<el-form-item label="客户姓名" prop="real_name" class="from-item-second">
						<el-input v-model.trim="ruleForm.real_name" autocomplete="off" clearable placeholder="输入姓名"></el-input>
					</el-form-item>
					
					<el-form-item label="客户性别" prop="gender">
						<el-select v-model="ruleForm.gender"
											 filterable
											 placeholder="选择性别"
											 @change="handleSelect">
							<el-option
								v-for="item in genderOptions"
								:key="item.id"
								:label="item.value"
								:value="item.id">
							</el-option>
						</el-select>
					</el-form-item>
				</div>
				
				
				<!--一行2个选项两端对齐-->
				<div style="display: flex; justify-content: space-between">
					<el-form-item label="客户年龄" prop="age" class="from-item-second">
						<el-input v-model.trim.number="ruleForm.age" autocomplete="off" clearable placeholder="输入年龄"></el-input>
					</el-form-item>
					
					<el-form-item label="客户手机" prop="mobile" class="from-item-second">
						<el-input v-model.trim.number="ruleForm.mobile" autocomplete="off" clearable placeholder="输入手机"></el-input>
					</el-form-item>
				</div>
				
				<!--一行2个选项两端对齐-->
				<div style="display: flex; justify-content: space-between">
					<el-form-item label="客户状态" prop="buy_status" class="from-item-second">
						<el-select v-model="ruleForm.buy_status"
											 filterable
											 placeholder="选择状态"
											 @change="handleSelect">
							<el-option
								v-for="item in buyStatusOptions"
								:key="item.id"
								:label="item.value"
								:value="item.id">
							</el-option>
						</el-select>
					</el-form-item>
					
					<el-form-item label="咨询产品" prop="goods" class="from-item-second">
						<el-select v-model="ruleForm.goods_id"
											 filterable
											 placeholder="选择产品"
											 @change="handleSelect">
							<el-option
								v-for="item in goodsOptions"
								:key="item.goods_id"
								:label="item.goods_name"
								:value="item.goods_id">
							</el-option>
						</el-select>
					</el-form-item>
				</div>
				
				
				<!--一行2个选项两端对齐-->
				<div style="display: flex; justify-content: space-between">
					
					<el-form-item label="渠道来源" prop="goods" class="from-item-second">
						<el-select v-model="ruleForm.from_id"
											 filterable
											 placeholder="渠道来源"
											 @change="handleSelect">
							<el-option
								v-for="item in channelFromOptions"
								:key="item.id"
								:label="item.name"
								:value="item.id">
							</el-option>
						</el-select>
					</el-form-item>
					
					
					<el-form-item label="到期日期" prop="expired_at" v-if="ruleForm.buy_status == 2">
						<el-date-picker
							v-model="ruleForm.expired_at"
							type="date"
							format="yyyy 年 MM 月 dd 日"
							value-format="yyyy-MM-dd"
							placeholder="到期日期">
						</el-date-picker>
					</el-form-item>
					
				</div>
				
			</el-form>
			
			<div slot="footer" class="dialog-footer">
				<el-button class="mgr-20" size="mini" @click="handleCancel">取 消</el-button>
				<el-button size="mini" type="primary" @click="handleConfirm('ruleForm')">确 定</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
import UploadImage from '@/components/UploadImage/UploadImage';
import {postAddCustomerRecord, getGoods, getChancelFrom} from '@/api/ggkq';

export default {
  name: "DialogAddCustomer",
  components: {
    // UploadImage,
  },
  props: {
    width: { // 弹出框的宽度配置
      type: String,
      default: '640',
    },
    isActiveDialog: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    cssVars() {
      return {
        "--widthToOne" : this.width - 50 + "px",
        "--widthToTwo" : this.width / 2 - 40+ "px",
      }
    }
  },
	async created () {
    await this.handleGetGoods()
    await this._getChancelFrom()
  },
  data () {
    return {
      searchParams: {},
      listLoading: false,
      genderOptions: [{
        value: '男',
        id: '1'
      },{
        value: '女',
        id: '2'
      }],
      buyStatusOptions: [
        {
          value: '未购',
          id: '1'
        },{
          value: '已购',
          id: '2'
        }
			],
			goodsOptions: [],
      channelFromOptions: [],
      ruleForm: {
        real_name: '',
        gender: '',
        age:  '',
        from_id: '',
        mobile:'',
        buy_status: '1',
        goods_id:'',
        expired_at: '',
      },
      rules: {
        real_name: [
          { required: true, message: '姓名', trigger: 'blur' }
        ],
        gender: [
          { required: true, message: '性别', trigger: 'blur' }
        ],
        age: [
          { required: true, message: '年龄', trigger: 'blur' }
        ],
        mobile: [
          { required: true, message: '手机号', trigger: 'blur' }
        ],
        buy_status: [
          { required: true, message: '购买状态', trigger: 'blur' }
        ],
        goods_id: [
          { required: true, message: '咨询商品', trigger: 'blur' }
        ],
        expired_at: [
          { required: true, message: '到期日期', trigger: 'blur' }
        ],
      }
    }
  },
  methods: {
    async _getChancelFrom() {
      var params = {
        c_type: 'from',
				status: 1
			}
      var result = await getChancelFrom(params)
			console.log(result)
			this.channelFromOptions = result.data
		},
    async handleGetGoods() {
      let result = await getGoods()
			this.goodsOptions = result.data
		},
		async handlePostAddCustomerRecord () {
      var result
      if (this.ruleForm.buy_status == 1) {
				var data = {
          real_name: this.ruleForm.real_name,
          gender: this.ruleForm.gender,
          age:  this.ruleForm.age,
          mobile: this.ruleForm.mobile,
          goods_id: this.ruleForm.goods_id,
          buy_status: '1',
          from_id: this.ruleForm.from_id
				}
        result = await postAddCustomerRecord(data)
			}else {
        result = await postAddCustomerRecord(this.ruleForm)
			}
   
			return result
		},
    handleSelect () {

    },
    handleConfirm (ruleForm) {
      this.submitForm(ruleForm)
    },
    handleCancel (e) {
      this.resetForm('ruleForm')
      this.$emit('update:isActiveDialog', false)
    },
    submitForm (formName) {
      console.log(this.$refs[formName])
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          // 出发test页面更新列表
					var result = await this.handlePostAddCustomerRecord()
					if(result.code == 200) {
            this.$message({
              type: "success",
              message: '创建成功',
            });
            this.$emit('getList')
            this.$emit('update:isActiveDialog', false)
					} else {
            this.$message({
              type: "error",
              message: result.msg,
            });
					}
        
        } else {
          console.log('error submit!!');
          return false;
        }
      })
    },
    resetForm (formName) {
      this.$refs[formName].resetFields();
    },
  }
}
</script>

<style scoped lang="scss">
	/*竖杠+后面文本的样式*/
	.required {
		margin-top: 2px;
		display: inline-block;
		width: 4px;
		height: 14px;
		background-color: #2362FB;
		top: 20px;
	}
	
	.required+span {
		display: inline-block;
		margin-left: 4px;
		vertical-align: top;
		font-weight: bold;
		font-size: 14px;
	}
	
	
	/*dialog对话框样式调整*/
	/deep/ .el-dialog__body {
		padding-bottom: 0 !important;
	}
	
	/deep/ .el-dialog__footer {
		background-color: #fafafa;
		border-top: 1px solid #eaeaea;
		padding: 10px 30px 10px 0;
	}
	
	/*dialog对话框样式调整*/
	/deep/ .el-dialog__body {
		padding-bottom: 0 !important;
	}
	
	/deep/ .el-dialog__footer {
		background-color: #fafafa;
		border-top: 1px solid #eaeaea;
		padding: 10px 30px 10px 0;
	}
	
	
	/*弹出框表单配置*/
	/deep/ .el-form-item__label {
		padding-bottom: 0;
	}
	
	/deep/ .el-form-item__content {
		width: var(--widthToTwo);
	}
	
	.from-item-second {
		margin-left: 0;
	}
	
	/deep/ .from-item-third {
		.el-form-item__content {
			width: var(--widthToOne);
		}
		.el-select{
			width: var(--widthToOne) !important;
		}
	}
	
	/deep/ .el-cascader {
		width: var(--widthToTwo);
	}
	
	/deep/ .el-select {
		width: var(--widthToTwo) !important;
	}
	
	/deep/ .el-date-editor {
		width: var(--widthToTwo) !important;
	}
	
	/*select 宽度配置*/
	/deep/ .el-select {
		width: 85px;
	}
</style>

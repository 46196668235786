import { render, staticRenderFns } from "./dialogAddCustomer.vue?vue&type=template&id=64256e78&scoped=true&"
import script from "./dialogAddCustomer.vue?vue&type=script&lang=js&"
export * from "./dialogAddCustomer.vue?vue&type=script&lang=js&"
import style0 from "./dialogAddCustomer.vue?vue&type=style&index=0&id=64256e78&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "64256e78",
  null
  
)

export default component.exports